import Vue from 'vue'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import authentication from './authentication'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false

// Setup axios as the Vue default $http library
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL

const moment = require('moment')
require('moment/locale/nl')

Vue.use(require('vue-moment'), {
  moment
})

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

console.debug('version:' + process.env.VUE_APP_VERSION); /* eslint-disable-line no-console */
Vue.prototype.$version = process.env.VUE_APP_VERSION;
console.debug('locale: ' + Vue.moment().locale()) /* eslint-disable-line no-console */

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
var printMediaQuery = window.matchMedia('print');

// Init adal authentication - then create Vue app.
// eslint-disable-next-line
authentication.initialize().then(_ => {

  /* eslint-disable no-new */
  new Vue({
    router,
    axios,
    vuetify,
    render: h => h(App),
    data: () => ({
      me: null,
      scPermissions: [],
      baseOrganizations: [],
      organization: null,
      profilePhoto: null,
      graphToken: null,
      dialogErrorCode: null,
      dialogMessage: null,
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      phoneRegex: /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^00[0-9]{2}|^0)([0-9\-\s]{8,12}$)/,
      isPrinting: false,
      appDrawer: true,
      /* must match the enum in models! */
      statusTypes: [
        { id: 0, name: "Aangemaakt", disableOnOpenTasks: false },
        { id: 1, name: "In behandeling", disableOnOpenTasks: false },
        { id: 2, name: "Onderzoek", disableOnOpenTasks: false },
        { id: 3, name: "Gepland", disableOnOpenTasks: false },
        { id: 4, name: "Oplossen", disableOnOpenTasks: false },
        { id: 5, name: "Wachten op melder", disableOnOpenTasks: false },
        { id: 6, name: "Wachten op leverancier", disableOnOpenTasks: false },
        { id: 7, name: "Afgehandeld", disableOnOpenTasks: false },
        { id: 8, name: "Gesloten", errorMessage: "Sluit eerst alle openstaande taken", disableOnOpenTasks: true },
        { id: 9, name: "Gearchiveerd", errorMessage: "Sluit eerst alle openstaande taken", disableOnOpenTasks: true },
      ],
      /* must match the enum in models! */
      impacts: [
        { id: 0, name: "Geen" },
        { id: 1, name: "Laag", color: "primary" },
        { id: 2, name: "Medium", color: "warning" },
        { id: 3, name: "Hoog", color: "red" },
      ],
      priorities: [
        { id: 0, name: 'Geen', icon: "mdi-circle-medium" },
        { id: 1, name: 'Laag', icon: "mdi-chevron-down", color: "primary" },
        { id: 2, name: 'Medium', icon: "mdi-equal", color: "warning" },
        { id: 3, name: 'Hoog', icon: "mdi-chevron-up", color: "red" },
      ],
      /* must match the enum in models! */
      projectTypes: [
        { id: 0, name: 'Administratief' },
        { id: 1, name: 'Productontwikkeling' },
        { id: 2, name: 'Project' },
        { id: 3, name: 'Service' }
      ],
      /* must match the enum in models! */
      hourbookingTypes: [
        { id: 0, name: 'Direct' },
        { id: 1, name: 'Indirect' },
        { id: 2, name: 'Ziekte' },
        { id: 3, name: 'Parttime' },
        { id: 4, name: 'Feestdagen' },
        { id: 5, name: 'Verlof standaard' },
        { id: 6, name: 'Verlof bijzonder' },
        { id: 7, name: 'Verlof gekocht' },
        { id: 8, name: 'Verlof compensatie' },
        { id: 9, name: 'Verlof restant vorige periode' },
      ],
      /* must match the enum in models! */
      ioTypes: [
        { id: 0, name: 'Overig' },
        { id: 1, name: 'Detector' },
        { id: 2, name: 'Overige input' },
        { id: 3, name: 'Signaalgroep' },
        { id: 4, name: 'Overige output' },
        { id: 5, name: 'Output wachtsignaal' },
        { id: 6, name: 'Softwareflag' },
        { id: 7, name: 'Softwareflag error' },
        { id: 8, name: 'Softwareflag inverted error' },
        { id: 9, name: 'Softwareflag wachtsignaal' },
        { id: 10, name: 'Timer' },
        { id: 11, name: 'Parameter' },
        { id: 12, name: 'Counter' },
      ],
      ioShapes: [
        { id: 0, title: 'Niet verklikken', icon: 'mdi-cancel', showMissingShapeWarning: false },
        { id: 1, title: 'Verklikken als polygoon', icon: 'mdi-vector-polygon', showMissingShapeWarning: true },
        { id: 2, title: 'Verklikken als cirkel', icon: 'mdi-circle-outline', showMissingShapeWarning: true },
        { id: 3, title: 'Verklikken als nummer', icon: 'mdi-numeric', showMissingShapeWarning: false }
      ],
      organizationTypes: [
        { id: 0, name: "Tree node", enable: false },
        { id: 1, name: "Interne organisatie", enable: true, icon: 'mdi-sitemap-outline' },
        { id: 2, name: "Klanten", enable: true, icon: 'mdi-handshake-outline' },
        { id: 3, name: "Leveranciers", enable: true, icon: 'mdi-cart-outline' },
        { id: 3, name: "Aannemers", enable: true },
      ],
      ticketTypes: [
        { id: 0, name: 'Storing', description: 'Storing / correctief onderhoud. Indien de installatie onderdeel is van een SLA is deze van toepassing.' },
        { id: 1, name: 'Informatieverzoek', description: 'Voor het stellen van vragen of opvragen van gegevens. SLA niet van toepassing.' },
        { id: 2, name: 'Onderhoud', description: 'Gepland en preventief onderhoud. SLA niet van toepassing.' }
      ],
      tokenExpire: null
    }),
    computed: {
      myName() {
        if (this.me) {
          return ((this.me.firstname || '') + ' ' + (this.me.surnamePrefix || '') + ' ' + (this.me.surname || '')).replace('  ', ' ')
        } else {
          return null
        }
      },
      isAuthenticated() {
        return authentication.isAuthenticated() ? (
          authentication.getUserProfile().roles &&
          authentication.getUserProfile().roles.length > 0 &&
          authentication.getUserProfile().roles.includes('User')) : false
      },
      isAdmin() {
        return authentication.isAuthenticated() ? (
          authentication.getUserProfile().roles &&
          authentication.getUserProfile().roles.length > 0 &&
          authentication.getUserProfile().roles.includes('Admin')) : false
      },
      myPermissions() {
        return this.me ? this.me.permissions : []
      },
      myNotifications() {
        return this.me ? this.me.notifications.map(x => x.notificationName) : []
      },
      myScPermissions() {
        return this.scPermissions.map(scp =>
        ({
          id: scp.serviceCategory.id,
          name: scp.serviceCategory.name,
          canCreate: scp.canCreate,
          canReadWrite: scp.canReadWrite,
          canManage: scp.canManage
        }))
      }
    },
    methods: {
      getProfileData() {
        this.$http
          .get('https://graph.microsoft.com/v1.0/me/', { headers: { Authorization: this.graphToken } })
          .then(request => {
            if (request.status == 200) {
              this.$http
                .get('api/organization/base')
                .then(request => {
                  this.organization = request.data;
                  this.$http.get('api/employee')
                    .then(request => {
                      this.me = request.data;
                      this.getBaseOrganizations();
                      this.getPermissions();
                      this.getScPermissions();
                    })
                    .catch((e) => {
                      console.error('employee error!')  /* eslint-disable-line no-console */
                      console.debug(e)  /* eslint-disable-line no-console */
                    }
                    )
                })
                .catch((e) => {
                  console.error('organization error!')  /* eslint-disable-line no-console */
                  console.debug(e) /* eslint-disable-line no-console */
                }
                )
            }
          })
          .catch((e) => {
            console.error('profile data error!') /* eslint-disable-line no-console */
            console.debug(e) /* eslint-disable-line no-console */
          }
          )
      },
      getPermissions() {
        this.$http.get('api/employee/permissions')
          .then(request => {
            this.me.permissions = request.data
          })
          .catch((e) => {
            console.error('permission error!')  /* eslint-disable-line no-console */
            console.debug(e)  /* eslint-disable-line no-console */
          })
      },
      getScPermissions() {
        this.$http.get('api/employee/servicecategorypermissions')
          .then(request => {
            this.scPermissions = request.data
          })
          .catch((e) => {
            console.error('service category permission error!')  /* eslint-disable-line no-console */
            console.debug(e)  /* eslint-disable-line no-console */
          })
      },
      getBaseOrganizations() {
        this.$http.get('api/employee/baseorganizations')
          .then(request => {
            this.baseOrganizations = request.data;
            this.$root.$emit('baseOrgsLoaded', '');
          })
          .catch((e) => {
            console.error('base organizations error!')  /* eslint-disable-line no-console */
            console.debug(e)  /* eslint-disable-line no-console */
          })
      },
      getProfileImage() {
        this.$http.get('https://graph.microsoft.com/v1.0/me/photo/$value', { headers: { Authorization: this.graphToken }, responseType: 'arraybuffer' })
          .then(request => {
            this.setProfilePhoto(request.data)
          })
          .catch((e) => { console.log('profile photo error!' + e) }) /* eslint-disable-line no-console */
      },
      setProfilePhoto(photo) {
        var bytes = new Uint8Array(photo)
        var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '')
        this.profilePhoto = "data:image/jpeg;base64," + btoa(binary)
      },
      logOut() {
        authentication.signOut()
      },
      clearDialog() {
        this.dialogErrorCode = null;
        this.dialogMessage = null;
      },
      getSlaString(response) {
        if (response === null || response === undefined) return "nvt"
        else if (response.trim().includes("TBD")) return "In overleg"
        else if (response.trim().includes("NBD")) return "vlgd wd"
        else if (response.trim().includes("BD")) return response.replace("BD", "wd")
        else if (response.trim().includes("D")) return response.replace("D", "d")
        else if (response.trim().includes("OH")) return response.replace("OH", "u binnen kantoortijden")
        else if (response.trim().includes("H")) return response.replace("H", "u")
        else if (response.trim().includes("SC")) return "Zie contract"
      },
      projectTypeById(id) {
        var result = this.projectTypes.find(x => x.id == id);
        if (!result) return '';
        return result.name;
      },
      hourbookingTypeById(id) {
        var result = this.hourbookingTypes.find(x => x.id == id);
        if (!result) return '';
        return result.name;
      },
      copyClipboard(text) {
        try {
          this.$nextTick(() => {
          navigator.clipboard.writeText(text);
        });
        this.$root.$emit('display-message', '\'' + text + '\' gekopieerd naar klembord', 'info');
        }
        catch (err) {
          console.log(err); /* eslint-disable-line no-console */
        }
      },
      setToken() {
        authentication.acquireToken().then(token => {
          this.$http.defaults.headers.common['Authorization'] = `Bearer ${token ? token : ""}`
          this.tokenExpire = authentication.getTokenExpiration();
          authentication.acquireToken('https://graph.microsoft.com').then(graphToken => {
            this.graphToken = graphToken
            this.getProfileData()
            this.getProfileImage()
          })
            .catch((e) => {
              console.log('graphtoken error!') /* eslint-disable-line no-console */
              console.log(e) /* eslint-disable-line no-console */
            })
        })
          .catch((e) => {
            console.log('token error!') /* eslint-disable-line no-console */
            console.log(e) /* eslint-disable-line no-console */
          });
      }
    },
    mounted() {
      this.$on('display-message', (code, msg, type) => {
        if (type == "error") {
          if (msg.response && msg.response.data) this.dialogMessage = msg.response.data;
          else this.dialogMessage = msg;
          this.dialogErrorCode = code;
        }
      });
      this.$on('display-message', (msg, type) => {
        if (type == "info") {
          this.dialogMessage = msg
        }
      });
      this.$on('toggleDrawer', (state) => {
          this.appDrawer = state;
      }); 
      try {
        this.isPrinting = printMediaQuery.matches;
        printMediaQuery.addEventListener('change', (e) => {
          this.isPrinting = e.matches;
        });
      }
      catch(err) {
        console.warn('failed to monitor mediatype', err); /* eslint-disable-line no-console */
      }
      this.setToken();
    }
  }).$mount('#app')
})